import React from "react"
import { Link } from "gatsby"
import Video from "../../components/video"

import Layout from "../../components/layout"
import LinkExternal from "../../components/link_external"
import Seo from "../../components/seo"
import Routes from "../../routes"
import MattDillahuntyImg from "../../images/matt-dillahunty.jpg"
import CommentSection from "../../components/comment_section"
import { useImpression } from "../../analytics/utils"

const MattDillahunty = ({ location }) => {
  useImpression("MattDillahunty")
  return (
    <Layout location={location}>
      <Seo
        title="The Best of Matt Dillahunty"
        description="Explore the famous destroyer of religion, Matt Dillahunty. Find his best quotes and facts about religion and theism."
      />
      <h1>Matt Dillahunty and The Atheist Experience</h1>
      <div className="flex-center">
        <img
          src={MattDillahuntyImg}
          alt="Matt Dillahunty is a well known atheist and host of The Atheist Experience talk show."
          width="380"
          style={{
            padding: 2,
            textAlign: "center",
          }}
        />
      </div>

      <h2>Who is Matt Dillahunty?</h2>
      <p>
        Matt is a well known ex-religious person, who's become one of the most
        feared atheists around. He has a tendency to get frustrated by constant
        irrational callers on his show,{" "}
        <LinkExternal to="https://www.youtube.com/user/TheAtheistExperience">
          The Atheist Experience
        </LinkExternal>
        , and ends up shutting them down and cutting off the call. Matt's
        arguments are amazingly resilient, and he can find the cracks in every
        single theist caller that calls in.
      </p>
      <p>
        Unsurprisingly, Dillahunty comes from a religious background. In fact,
        he was on his way to becoming a minister! But during his research, he
        discovered just how obscure, wicked, and disgusting the Southern Baptist
        belief system really is.
      </p>
      <p>
        Matt now devotes a massive part of his life to spreading his atheist
        views in different TV shows, debates, and speeches. Unlike some of our
        other favorite atheists, like{" "}
        <Link to={Routes.DAWKINS}>Richard Dawkins</Link>, Dillahunty has much
        less tolerance for religious mumbo-jumbo and isn't afraid to say it.
        Many times, religious believers will hide behind their vague ideas,
        avoiding drilling into the core of the proposition. Anyone who tries to
        circumvent his line of questioning will either be swiftly hung up on or
        absolutely decimated by rational and logic arguments. So if you were
        thinking about calling into the Atheist Experience, you might want to
        think again.
      </p>
      <h2>Best Moments From The Atheist Experience</h2>
      <p>
        In episode 696, Matt engages with a caller from Austin, Texas. The
        caller provides a muddy description
      </p>
      <blockquote>
        "Why would you believe anything on faith. Faith isn't a pathway to
        truth. Every religion has some sort of faith. If faith is your pathway
        you can't distinguish between Christianity, Hinduism, Judaism, any of
        these others. How is it that you use reason as a path to truth in every
        endeavor of your life. And then when it comes to the ultimate truth, the
        most important truth, you're saying that faith is required. And how does
        that reflect on a god that supposedly exists and wants you to have that
        information. What kind of god requires faith instead of evidence?"
      </blockquote>
      <blockquote>
        "Faith is the excuse people give for believing something when they don't
        have evidence. If you can come up with something that i believe that I
        don't have evidence for, guess what I'll do. I'll stop believing it.
        That's the nature of a rational mind. That is the goal."
      </blockquote>
      <blockquote>
        "Faith is not a virtue. Faith is gullibility. It's evidence that
        determines whether or not your perception of reality is reasonable and
        in conjunction with the world as it is."
      </blockquote>
      <blockquote>
        "The absurdity of the device nature of Christianity in particular, and
        by the way, I'm an atheist with regard to all gods, but since you're
        kind of representing Christianity... it breaks my heart. People who
        actually understand what love is, people who actually understand what
        morality is, people who actually understand reality, it is almost
        unbearable to watch the people that you love to be so absolutely duped
        into a divisive, hateful religion that they think is not decisive (they
        think it's inclusive) and they think it's positive. It kills me, and
        it's one of the reasons that I do this. Because I, for 25 plus years
        believed this stuff. I am so happy, so happy, that I no longer think my
        former roommate is destined for hell. I am so happy that despite the
        fact that the relationship with my parents, the nature of it has
        changed, I don't have to worry about them. The division is entirely
        one-sided. I didn't end relationships when I became an atheist.
        Christians ended those relationships. And it's because their particular
        religion cannot tolerate... I had letters from people who said we can no
        longer associate with you, you are of the devil."
      </blockquote>
      <p>
        Take a moment to soak in one of the world's greatest religious rebuttals
        of all time.
      </p>
      <div className="flex-center">
        <Video controls url="https://youtu.be/rJ6ruM7Muo8" />
      </div>
      <br />
      <hr />
      <p>
        Be sure to check out our{" "}
        <Link to={Routes.HITCHENS}>Christopher Hitchens</Link> to learn more
        about another awesome atheist.
      </p>
      <CommentSection />
    </Layout>
  )
}

export default MattDillahunty
